// import Image from 'next/image';
'use client';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';

import { openGameProvider } from '@/components/core/OpenGame';
import { useAppRouter } from '@/components/hooks/useAppRouter';

export const RenderProviderGames = (
  gameItems: any[] = [],
  setProvider?: any,
  className = '',
  itemClassName = ''
) => {
  const [loaded, setLoaded] = useState(false);
  const { router } = useAppRouter();
  const onSetProvider = (item: any) => {
    setProvider && typeof setProvider === 'function' && setProvider(item);
  };
  return (
    <div
      className={clsx(
        'hide-scroll-bar flex snap-x snap-mandatory items-center gap-2 overflow-x-auto px-1',
        className
      )}
    >
      {gameItems?.map((_, index) => {
        console.log(
          '_?.lobbyCate',
          _?.lobbyCate && setProvider && typeof setProvider === 'function'
        );
        return (
          <div
            onClick={() =>
              _?.lobbyCate && setProvider && typeof setProvider === 'function'
                ? onSetProvider(_)
                : openGameProvider(_, router)
            }
            className={clsx('shadow-box-light relative my-[8px] rounded-xl')}
            key={_.key}
          >
            <div
              className={clsx(
                'vw:min-h-[100px] min-w-full',
                !loaded && 'loading-bg rounded-xl',
                itemClassName
              )}
            >
              {index === 0 && (
                <img
                  className='absolute top-0 w-[29px]'
                  src='/img/game/hot/hot-tag.png'
                  alt='hot-tag'
                />
              )}
              {index === 1 && (
                <img
                  className='absolute top-0 w-[29px]'
                  src='/img/game/hot/new-tag.png'
                  alt='new-tag'
                />
              )}

              <Image
                onLoad={() => {
                  setLoaded(true);
                }}
                className='h-full w-full rounded-xl'
                src={_.mobileImg || _.img}
                key={_.key}
                width={456}
                height={110}
                alt={_.key}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

// <div className='px-2'>
//     {gameItems?.map((_, index) => {
//       return (
//         <div
//           onClick={() =>
//             _?.lobbyCate && setProvider
//               ? onSetProvider(_)
//               : openGameProvider(_, router)
//           }
//           className={clsx(
//             'shadow-box-light hover-shine relative my-[8px] w-full rounded-[8px]'
//           )}
//           key={_.key}
//         >
//           {index === 0 && (
//             <img
//               className='absolute top-0 w-[29px]'
//               src='/img/game/hot/hot-tag.png'
//               alt='hot-tag'
//             />
//           )}
//           {index === 1 && (
//             <img
//               className='absolute top-0 w-[29px]'
//               src='/img/game/hot/new-tag.png'
//               alt='new-tag'
//             />
//           )}
//           <img
//             className='w-full'
//             src={_.mobileImg ? _.mobileImg : _.img}
//             key={_.mobileImg ? _.mobileImg : _.img}
//             width={321}
//             height={100}
//             alt='game-item'
//           />
//         </div>
//       );
//     })}
//   </div>
