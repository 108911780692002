// import showToast from '@/components/core/Toast/Toast';

import { getLinkGame } from '@/lib/apis';
import Notification from '@/lib/notification';

// import { kSport, virtualSport } from '@/constant/games/sportsGame';

export const openSportGame = async (item: any, is_new_tab?: any) => {
  try {
    const paramsUrl: any = {
      partner_provider: item.partner_provider,
      category_name: item.partner_game_id,
      partner_game_type: item.partner_game_type,
    };
    if (item?.matchId) {
      paramsUrl.matchId = item.matchId;
      paramsUrl.leagueId = item.leagueId;
    }
    const res = await getLinkGame(paramsUrl);

    if (res.code === 200) {
      if (typeof is_new_tab === 'boolean' && is_new_tab === true) {
        window.open(res.data, '_blank');
        return;
      }
      window.location.replace(res.data);
    } else {
      const statusMsg = res?.status;
      Notification.popup({
        type: 'error',
        title: 'Notification',
        description: statusMsg || res?.message,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Close',
      });
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  } catch (e: any) {
    const statusMsg = e?.status;
    Notification.popup({
      type: 'error',
      title: 'Notification',
      description: statusMsg || e?.message,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Close',
    });
    setTimeout(() => {
      window.close();
    }, 2000);
    console.error('logs:e', e);
  }
};
