'use client';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useState } from 'react';

import { openSportGame } from '@/lib/open-game/openSportGame';

import { TransitionAnimation } from '@/components/modules/AnimatedTransition/transitionConfig';
import { Page } from '@/components/modules/Page';
import MatchSlider from '@/components/modules/Slider/MatchSlider';

import { sports } from '@/constant/data';

// const mock = [
//   {
//     teams: [
//       {
//         name: 'Brighton',
//         image: 'https://static.j001d.com/img/hot_match/Brighton1.png',
//       },
//       {
//         name: 'Manchester City',
//         image: 'https://static.j001d.com/img/hot_match/Manchester_City1.png',
//       },
//     ],
//     league_name: 'England Premier League',
//     league_name_text: '',
//     event_id: '1663163',
//     league_id: '76',
//     text_time: '2024-04-25T19:00:00Z',
//     type: 'hotmatch',
//     news_url: '',
//     hdp: {
//       hTeam: {
//         rate: '1.00',
//         odds: '-0.92',
//       },
//       aTeam: {
//         rate: '-1.00',
//         odds: '0.8',
//       },
//     },
//     ou: {
//       hTeam: {
//         rate: '3.25',
//         odds: '-0.99',
//       },
//       aTeam: {
//         rate: '-3.25',
//         odds: '0.85',
//       },
//     },
//     position: 1,
//     client_id: 'bc119193',
//     score: [null, null],
//     ext_score: [null, null],
//     _id: '66220d8b50638a035f47293c',
//     id: 56340,
//     created_time: '2024-04-19T06:22:03.136Z',
//     last_updated_time: '2024-04-19T06:22:03.136Z',
//     error: false,
//   },
// ];

const SportGames: React.FC<any> = (props) => {
  const { topMatch } = props;
  const [loaded, setLoaded] = useState(false);

  return (
    <Page
      className='vw:w-[100%] mx-auto pb-28 pt-3'
      animation={TransitionAnimation.SLIDE_UP_2}
    >
      <h2 className='vw:mb-3 vw:pl-5 flex items-center'>
        <img
          className='vw:w[19px] vw:h-[19px]'
          src='/img/game/sport/ball.png'
          alt=''
        />
        <span className='vw:text-16 vw:ml-1 font-bold'>Sportbook</span>
      </h2>
      {topMatch?.length > 0 && (
        <>
          <div className='hide-scroll-bar w-full'>
            <MatchSlider data={topMatch} />
          </div>
        </>
      )}

      <div
        className={clsx(
          'hide-scroll-bar flex snap-x snap-mandatory items-center gap-2 overflow-x-auto px-1',
          'vw:!px-4 vw:!gap-2 w-full overflow-x-scroll '
        )}
      >
        {sports?.map((_: any, index: any) => {
          return (
            <div
              onClick={() => openSportGame(_, true)}
              className={clsx('shadow-box-light relative my-[8px] rounded-xl')}
              key={_.key}
            >
              <div
                className={clsx(
                  'vw:min-h-[100px] min-w-full',
                  !loaded && 'loading-bg rounded-xl',
                  'vw:min-w-[116px] vw:max-w-[116px] w-full'
                )}
              >
                {index === 0 && (
                  <img
                    className='absolute top-0 w-[29px]'
                    src='/img/game/hot/hot-tag.png'
                    alt='hot-tag'
                  />
                )}
                {index === 1 && (
                  <img
                    className='absolute top-0 w-[29px]'
                    src='/img/game/hot/new-tag.png'
                    alt='new-tag'
                  />
                )}

                <Image
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  className='h-full w-full rounded-xl'
                  src={_.mobileImg || _.img}
                  key={_.key}
                  width={456}
                  height={110}
                  alt={_.key}
                />
              </div>
            </div>
          );
        })}
      </div>
      {/* TODO: TBU */}
      {/* <h2 className='vw:mb-2 vw:mt-4 vw:pl-4 flex items-center'>
        <img
          className='vw:w[19px] vw:h-[19px]'
          src='/img/game/sport/ball.png'
          alt=''
        />
        <span className='vw:text-16 vw:ml-1 font-bold'>Virtual Sport</span>
      </h2>
      {RenderProviderGames(
        esports.filter((i: any) => i.isVirtual),
        null,
        'w-full vw:!px-4 overflow-x-scroll vw:!gap-2 ',
        'vw:min-w-[116px] vw:max-w-[116px] w-full'
      )}

      <h2 className='vw:mb-2 vw:mt-4 vw:pl-4 flex items-center'>
        <img
          className='vw:w-[19px] vw:h-[19px]'
          src='/img/game/sport/play.png'
          alt=''
        />
        <span className='vw:text-16 vw:ml-1 font-bold'>Esport</span>
      </h2>
      {RenderProviderGames(
        esports.filter((i: any) => !i.isVirtual),
        null,
        'w-full vw:!px-4 overflow-x-scroll !gap-2 ',
        'vw:min-w-[116px] vw:max-w-[116px] w-full'
      )} */}
    </Page>
  );
};

export default SportGames;
